import React from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  IconButton,
  VStack,
  HStack,
  Button,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaEnvelope, FaPhone, FaComment } from 'react-icons/fa';

const ContactCard = () => {
  const handleButtonClick = () => {
    const composeUrl = 'https://mail.google.com/mail/u/0/#inbox?compose=new';
    window.open(composeUrl, '_blank');
  };

  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.200');
  const iconColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Flex
      w="100%"
      minH="60vh"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(to-t,#201E43, #201E43)"
      boxShadow="xl"
      position="relative"
      overflow="hidden"
    >
     <Box
  w={{ base: '90%', sm: '80%', md: '70%', lg: '60%' }}
  maxW="5xl"
  p={8}
  bg={cardBg}
  borderRadius="xl"
  boxShadow="2xl"
  color={textColor}
  textAlign="center"
  position="relative"
  zIndex={1}
>
  <Heading as="h2" size={{ base: 'lg', md: 'xl' }} color="#135D66" mb={4}>
    Contact Us
  </Heading>
  <Text
    fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
    mt={{ base: 3, md: 5 }}
    color={iconColor}
  >
    Need assistance? Reach out to us on WhatsApp for support with all our gaming services.
  </Text>

  <Box w={{ base: '90%', sm: '80%', md: '70%', lg: '100%' }} margin="auto" mt={5}>
    <Button
      as="div"
      borderRadius="full"
      bgColor="green.100"
      color="green.500"
      fontWeight="bold"
      fontSize={{ base: '14px', md: '16px', lg: '18px' }}
      borderWidth="2px"
      borderColor="green.500"
      cursor="pointer"
      transition="transform 0.3s ease-in-out, border 0.3s ease-in-out"
      _hover={{ transform: 'scale(1.05)', borderColor: 'green.800', bgColor: 'green.200' }}
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
      p={4}
      textAlign="center"
      // onClick={() => window.location.href = 'https://wa.me/+447537176105'}
    >
      <FaComment/>
      <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} ml={2}>WhatsApp Us</Text>
    </Button>
  </Box>
</Box>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
       
        opacity={0.4}
        zIndex={0}
      />
    </Flex>
  );
};

export default ContactCard;
