import React from 'react';
import { Box, Container, Heading, Text, Stack, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Divider, HStack, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { FaPhoneAlt, FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';

const HelpPage = () => {
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const fontSize = useBreakpointValue({ base: 'md', md: 'md' });
  const headingSize = useBreakpointValue({ base: 'lg', lg: '2xl' }); // Large headings for larger screens

  return (
    <Container maxW="container.md" p={4}>
      <Heading mb={4} textAlign="center" color="teal.500" fontSize={headingSize}>
        Help & Support
      </Heading>
   
      <Stack spacing={6} mb={6}>
        <Box
          p={4}
          borderWidth={1}
          borderRadius="md"
          bgGradient="linear(to-r, teal.300, blue.300)"
          boxShadow="lg"
          textAlign="center"
        >
          <Heading size="md" mb={2} color="white">
            Contact Us
          </Heading>
          <HStack spacing={4} justify="center">
            {/* <IconButton
              icon={<FaWhatsapp />}
              aria-label="WhatsApp"
              colorScheme="whatsapp"
              bg="whatsapp.500"
              color="white"
              size={buttonSize}
              _hover={{ bg: 'whatsapp.600' }}
              onClick={() => window.location.href = 'https://wa.me/+447537176105'}
            >
              WhatsApp
            </IconButton> */}
            <IconButton
              icon={<FaTelegramPlane />}
              aria-label="Telegram"
              colorScheme="telegram"
              bg="telegram.500"
              color="white"
              size={buttonSize}
              _hover={{ bg: 'telegram.600' }}
              onClick={() => window.location.href = 'https://t.me/velodice'}
            >
              Telegram
            </IconButton>
          </HStack>
        </Box>

        <Box p={4} borderWidth={1} borderRadius="md" bg="white" boxShadow="md">
          <Heading size="md" mb={2} color="teal.500" fontSize={headingSize}>
            Frequently Asked Questions (FAQ)
          </Heading>
          <Accordion allowToggle>
            {/* Existing FAQs */}
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How can I reset my password?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                You can reset your password by clicking on the "Forgot Password" link on the login page. Follow the instructions to reset your password.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How do I sign up?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                To sign up, click on the "Sign Up" button on the login page and fill out the registration form with your details.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How can I withdraw funds?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                To withdraw funds, navigate to the "Withdraw" section in your wallet and follow the instructions to complete the process.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How do I make a deposit?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                To make a deposit, go to the "Deposit" section in your wallet and choose your preferred payment method. Follow the prompts to add funds.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How do I track my game history?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                You can view your game history by navigating to the "Bet History" section in your profile. Here you'll find detailed records of your gameplay.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How can I contact support if I encounter issues?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                If you encounter any issues, you can contact our support team through the "Help" section on this page
              </AccordionPanel>
            </AccordionItem>

            {/* Additional Game-Specific FAQs */}
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  What are the basic rules of the game?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                The game involves betting on the outcome of dice rolls. You place a bet on the sum of two dice, and if the sum matches the outcome, you win. For detailed rules, refer to the "How to Play" section.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  How is the payout calculated?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
              Payouts are calculated based on the difficulty of the game and its associated rates, rather than just the probability of the outcome. For games with higher difficulty or higher stakes, the payout rates are generally higher. Conversely, for easier games or those with lower stakes, the payout rates are lower. For specific payout details, please refer to the "Game Rates " in the game.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  What happens if there is a technical issue during gameplay?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                If a technical issue occurs, your bet will be refunded. Please contact our support team with details of the issue to ensure it is resolved promptly.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  Can I play the game on mobile?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                Yes, our game is fully compatible with mobile devices. You can play on both smartphones and tablets without any issues.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderColor="gray.200">
              <AccordionButton _expanded={{ bg: 'teal.100' }}>
                <Box flex="1" textAlign="left" color="teal.700" fontSize={fontSize}>
                  Are there any bonuses or promotions available?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                Yes, we offer various bonuses and promotions. Check the "Promotions" section on our website or subscribe to our newsletter to stay updated on the latest offers.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Stack>

      <Divider my={6} />
    </Container>
  );
};

export default HelpPage;
